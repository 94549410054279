import "normalize.css"
import "./src/styles/font-faces.css"
// custom CSS styles
import "./src/styles/index.css"

// gatsby-browser.js
export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    const element = document.querySelector(location.hash)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
}
